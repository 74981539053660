.app-wrapper-content {
    background-color: #FAFAFA;
    box-shadow: 0 2px 10px 0 rgba(68, 68, 74, 0.2) inset;
}

#react-modal-image-img {
    height: 85%;
    width: auto;
}

.animation {
    animation: heartbeat 2.5s ease-in-out infinite both;
    box-shadow: 0 0 8px 1px rgb(221, 90, 89) !important;
}

.animation h3 {
   color: rgb(255, 232, 235)
}

.animation:hover h3 {
    color: white;
}

@keyframes heartbeat {
    from {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transform-origin: center center;
        transform-origin: center center;
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
    10% {
        -webkit-transform: scale(0.91);
        transform: scale(0.91);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }
    17% {
        -webkit-transform: scale(0.98);
        transform: scale(0.98);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
    33% {
        -webkit-transform: scale(0.87);
        transform: scale(0.87);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }
    45% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
}
