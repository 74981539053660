.article_wrapper {
  background-color: #FAFAFA;
  box-shadow: 0 2px 10px 0 rgba(68, 68, 74, 0.2) inset;
  height: 100%;
  margin: 0 8%;
  font-family: Roboto, serif;
  font-size: 1em;
  font-weight: normal;
  white-space: pre-line;
  color: #44444A;
  display: grid;
  grid-template-columns: 2fr 7fr;
  grid-template-areas: "promo promo" "aside section" "swiper swiper";
}

.aside {
  grid-area: aside;
  justify-self: center;
  text-align: center;
  padding: 1em;
  width: 13em;

  img {
    cursor: pointer;
    border: 1px solid lightgray;
  }

  img:hover {
    transform: scale(1.02);
    transition: 0.3s;
  }
}

.section {
  grid-area: section;
  margin: 0 7% 0 4%;
  text-align: justify;

  h1 {
    color: #399D37;
    font-size: 1.2em;
  }

  h2 {
    margin-top: 1.5em;
    text-align: center;
    color: #399D37;
    font-size: 1.1em;
  }

  section p{
    font-style: italic;
  }

  p {
    margin: 3% 0;
  }

  a {
    color: #5B92E5;
  }
}

.news {
  h2 {
    text-transform: uppercase;
    font-size: 1.3em;
  }
  hr {
    color: #44444A;
  }
  .lastLine {
    margin-bottom: 3%;
  }
  div {
    margin-bottom: 3%;
    img {
      float: left;
      height: 10em;
      border: 1px solid lightgray;
      margin: 5px 2% 2% 0;
    }
    h3 {
      text-align: left;
      color: #399D37;
      margin-bottom: 0;
    }
    p {
      margin: 0;
    }

  }
}

@media screen and (max-width: 960px) {
  .article_wrapper {
    grid-template-areas: "promo promo" "section section" "aside aside" "swiper swiper";
    grid-template-columns: 1fr 1fr;
  }
  .section {
    margin: 0 0.5em 0 0.5em;
  }
  .aside {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    width: 100%;
    padding: 0;

    img {
      height: 15em;
    }
  }
}

@media screen and (max-width: 559px) {
  .article_wrapper {
    margin: 0 2%;
  }
}

@media screen and (max-width: 450px) {
  .news {
    div {
      img {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 420px) {
  .promo {
    flex-wrap: wrap;
  }
  .news {
    div {
      img {
        display: none;
      }
    }
  }
}