.header {
  height: 100px;
  background-color: #FAFAFA;
  box-shadow: 0 2px 10px 0 rgba(68, 68, 74, 0.2) inset;
  padding: 0 6%;
  font-family: Roboto, serif;
  font-size: 1em;
  font-weight: normal;
  white-space: pre-line;
  display: grid;
  align-items: center;
  grid-template-columns: minmax(320px, 4fr) repeat(3,1fr auto);
  grid-template-areas: "logo phoneImage phoneNumbers mailImage mail coordiantesImage coordinates";
  gap: 0.5em 0.5em;

  a {
    text-decoration: none;
    color: #44444A;
    span {
      font-size: 0.8em;
    }
  }
}

.logo {
  background-image: url("assets/Logo.png");
}

a img {
  display: block;
  height: 100%;
  margin: 0 auto;
}

.phoneImage {
  background-image: url("assets/Phone.png");
}

.mailImage {
  background-image: url("assets/Mail.png");
}

.coordinatesImage {
  background-image: url("assets/Coordinates.png");
}

.logo_wrapper ,.phoneImage_wrapper, .mailImage_wrapper, .coordinatesImage_wrapper {
  width: auto;
  height: 50%;
}

.logo, .phoneImage, .mailImage, .coordinatesImage {
  background-repeat: no-repeat;
  display: block;
  text-indent: -1000em;
  outline: 0;
  background-position: center center;
  background-size: contain;
  width: auto;
  height: 100%;
}

.phoneNumbers, .mail {
  list-style-type: none;
  margin: 0;
  padding: 0;
  width: fit-content;

  li {
    margin: 5px 0;
  }
}

@media screen and (max-width: 1300px) {
  .header {
    font-size: 0.6em;
    padding: 0 2%;
  }
  .coordinates_wrapper {
    line-height: 150%;
  }
}

@media screen and (max-width: 840px) {
  .header {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 50% 50%;
    padding: 2% 1% 3%;
    grid-template-areas:
            "logo logo logo"
            "phoneImage mailImage coordiantesImage";

    div:nth-child(1) {
      grid-column-start: 1;
      grid-column-end: 4;
    }

    div {
      height: 90%;
    }
  }
  .phoneNumbers_wrapper, .mail_wrapper, .coordinates_wrapper {
    display: none;
  }
}
