.article_wrapper {
  background-color: #FAFAFA;
  box-shadow: 0 2px 10px 0 rgba(68, 68, 74, 0.2) inset;
  height: 100%;
  margin: 0 8%;
  font-family: Roboto, serif;
  font-size: 1em;
  font-weight: normal;
  white-space: pre-line;
  color: #44444A;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-areas: "section" "aside";
}

.section {
  grid-area: section;
  margin: 0 2em 2em 2em;
  text-align: justify;

  h1 {
    color: #399D37;
    font-size: 1.2em;
    text-transform: uppercase
  }

  h2 {
    margin-top: 1.5em;
    font-size: 1em;
    text-align: center;
  }

  h3 {
    font-size: 1em;
    color: #399d37;
    margin: 1em 0;
  }

  p {
    margin: 0.5em 0 0;
    text-align: justify;
    a {
      color: #44444A;
    }
  }

  section {
    text-align: left;
    p a {
      color: #44444A;
    }
    a {
      color: #5B92E5;
    }
  }
}

.bolded {
  font-weight: bold;
  text-align: center;
}

@media screen and (max-width: 960px) {
  .section {
    margin: 0 0.5em 2em 0.5em;
  }

}

@media screen and (max-width: 690px) {
  .aside {
    div {
      height: 1680px;
    }
  }
}

@media screen and (max-width: 559px) {
  .article_wrapper {
    margin: 0 2%;
  }
}

@media screen and (max-width: 340px) {
  .aside {
    div {
      height: 2080px;
    }
  }
}
@media screen and (max-width: 290px) {
  .aside {
    div {
      height: 2230px;
    }
  }
}
